import { IParadiseProfilesListFilter } from "src/data/paradise/paradiseProfiles/types/paradiseProfileQueryTypes"

export const paradiseProfilesKeys = {
  all() {
    return ["paradiseProfiles"] as const
  },

  profileLists() {
    return [...this.all(), "list"] as const
  },

  profileList(filter?: IParadiseProfilesListFilter) {
    if (!filter) {
      return [...this.profileLists()] as const
    }

    return [...this.profileLists(), filter] as const
  },
} as const
