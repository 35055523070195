import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { paradiseProfilesKeys } from "src/data/paradise/paradiseProfiles/queries/paradiseProfileQueryCache"
import {
  IParadiseProfilesListFilter,
  IParadiseProfilesResponse,
} from "src/data/paradise/paradiseProfiles/types/paradiseProfileQueryTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

// ts-prune-ignore-next
export function useFetchParadiseProfiles(props?: {
  filter?: IParadiseProfilesListFilter
  options?: UseQueryOptions<
    IParadiseProfilesResponse,
    AxiosError,
    IParadiseProfilesResponse,
    ReturnType<typeof paradiseProfilesKeys.profileList>
  >
}) {
  async function fetchParadiseProfiles() {
    const response = await minutApiHttpClient.get(`${API_DEFAULT}/profiles`, {
      params: props?.filter,
    })

    return response.data
  }

  return useQuery({
    queryKey: paradiseProfilesKeys.profileList(props?.filter),
    queryFn: fetchParadiseProfiles,
    ...props?.options,
  })
}
